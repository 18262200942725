import React from 'react';

interface Props {
   inFooter?: boolean;
}

export const LinkedInLink: React.FC<Props> = ({ inFooter }) => {
   return (
      <a className="cursor-pointer" href="https://www.linkedin.com/in/ben-lammers-3bb8bb168/" aria-label="Open Ben's LinkedIn profile">
         <svg
            className={`h-9 w-9 ${inFooter ? 'h-14 w-14 sm:h-7 sm:w-7 md:h-9 md:w-9' : ''} transition-colors ${
               inFooter ? 'fill-linkedin hover:fill-linkedin-hover' : 'fill-amber-400 hover:fill-amber-500'
            }`}
            width="36"
            height="36"
            viewBox="0 0 36 36"
         >
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M1.5 4.257C1.5 3.5258 1.79047 2.82455 2.30751 2.30751C2.82454 1.79047 3.5258 1.5 4.257 1.5H31.74C32.1024 1.49941 32.4613 1.5703 32.7962 1.7086C33.1311 1.84691 33.4355 2.04992 33.6919 2.30601C33.9482 2.5621 34.1516 2.86624 34.2903 3.20102C34.4289 3.5358 34.5002 3.89464 34.5 4.257V31.74C34.5004 32.1024 34.4293 32.4614 34.2909 32.7963C34.1524 33.1313 33.9492 33.4356 33.693 33.692C33.4368 33.9483 33.1326 34.1516 32.7977 34.2903C32.4628 34.4289 32.1039 34.5002 31.7415 34.5H4.257C3.89482 34.5 3.53619 34.4286 3.2016 34.29C2.867 34.1513 2.56301 33.9481 2.30698 33.692C2.05095 33.4358 1.8479 33.1317 1.70943 32.797C1.57097 32.4624 1.4998 32.1037 1.5 31.7415V4.257ZM14.562 14.082H19.0305V16.326C19.6755 15.036 21.3255 13.875 23.805 13.875C28.5585 13.875 29.685 16.4445 29.685 21.159V29.892H24.8745V22.233C24.8745 19.548 24.2295 18.033 22.5915 18.033C20.319 18.033 19.374 19.6665 19.374 22.233V29.892H14.562V14.082ZM6.312 29.6865H11.124V13.875H6.312V29.685V29.6865ZM11.8125 8.718C11.8216 9.13001 11.7483 9.53968 11.5969 9.92298C11.4455 10.3063 11.219 10.6555 10.9308 10.9501C10.6427 11.2446 10.2985 11.4787 9.91868 11.6385C9.53882 11.7983 9.13086 11.8806 8.71875 11.8806C8.30664 11.8806 7.89868 11.7983 7.51882 11.6385C7.13895 11.4787 6.79483 11.2446 6.50665 10.9501C6.21847 10.6555 5.99204 10.3063 5.84064 9.92298C5.68924 9.53968 5.61593 9.13001 5.625 8.718C5.64281 7.90929 5.97658 7.13969 6.55483 6.57403C7.13308 6.00838 7.90984 5.69163 8.71875 5.69163C9.52766 5.69163 10.3044 6.00838 10.8827 6.57403C11.4609 7.13969 11.7947 7.90929 11.8125 8.718V8.718Z"
            />
         </svg>
      </a>
   );
};
