import React from 'react';

interface Props {
   type: 'github' | 'external';
   href: string;
   title: string;
   onCard?: boolean;
   ariaLabel: string;
}

export const Link: React.FC<Props> = ({ type, href, title, onCard, ariaLabel }) => {
   return (
      <a href={href} aria-label={ariaLabel}>
         <div
            className={`grid grid-cols-[max-content_1fr] min-w-[8rem] shadow-md hover:shadow-lg transition-all items-center duration-300 bg-dark-gray hover:bg-dark-gray-2 dark:bg-white dark:hover:bg-gray-300 ${
               onCard ? 'md:dark:bg-dark-gray md:dark:hover:bg-dark-gray-2' : ''
            }`}
         >
            {type === 'github' ? (
               <svg
                  className={`h-6 w-6 p-1 xs:h-8 xs:w-8 xs:p-[6px] pr-0 fill-white dark:fill-dark-gray ${onCard ? 'md:dark:fill-white' : ''}`}
                  viewBox="0 0 36 36"
               >
                  <path d="M18 0C8.055 0 0 8.25874 0 18.4553C0 26.6218 5.1525 33.5194 12.3075 35.9647C13.2075 36.1262 13.545 35.5726 13.545 35.0881C13.545 34.6498 13.5225 33.1965 13.5225 31.6508C9 32.5044 7.83 30.5204 7.47 29.4823C7.2675 28.9517 6.39 27.3138 5.625 26.8755C4.995 26.5295 4.095 25.6759 5.6025 25.6529C7.02 25.6298 8.0325 26.9909 8.37 27.5445C9.99 30.3359 12.5775 29.5515 13.6125 29.0671C13.77 27.8675 14.2425 27.0601 14.76 26.5987C10.755 26.1373 6.57 24.5455 6.57 17.4864C6.57 15.4794 7.2675 13.8184 8.415 12.5265C8.235 12.0651 7.605 10.1735 8.595 7.63588C8.595 7.63588 10.1025 7.15142 13.545 9.52754C14.985 9.1123 16.515 8.90468 18.045 8.90468C19.575 8.90468 21.105 9.1123 22.545 9.52754C25.9875 7.12836 27.495 7.63588 27.495 7.63588C28.485 10.1735 27.855 12.0651 27.675 12.5265C28.8225 13.8184 29.52 15.4563 29.52 17.4864C29.52 24.5686 25.3125 26.1373 21.3075 26.5987C21.96 27.1754 22.5225 28.2827 22.5225 30.0129C22.5225 32.4813 22.5 34.4653 22.5 35.0881C22.5 35.5726 22.8375 36.1493 23.7375 35.9647C27.3108 34.7278 30.4158 32.3732 32.6155 29.2322C34.8152 26.0912 35.9989 22.3221 36 18.4553C36 8.25874 27.945 0 18 0Z" />
               </svg>
            ) : (
               <svg
                  className={`h-6 w-6 p-1 xs:h-8 xs:w-8 xs:p-[8px] pr-0 fill-white dark:fill-dark-gray ${onCard ? 'md:dark:fill-white' : ''}`}
                  viewBox="0 0 16 16"
               >
                  <path d="M2.16667 3C2.16667 2.77899 2.25446 2.56702 2.41074 2.41074C2.56702 2.25446 2.77899 2.16667 3 2.16667H6.33333C6.55435 2.16667 6.76631 2.07887 6.92259 1.92259C7.07887 1.76631 7.16667 1.55435 7.16667 1.33333C7.16667 1.11232 7.07887 0.900358 6.92259 0.744078C6.76631 0.587797 6.55435 0.5 6.33333 0.5H3C2.33696 0.5 1.70107 0.763392 1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3V13C0.5 13.663 0.763392 14.2989 1.23223 14.7678C1.70107 15.2366 2.33696 15.5 3 15.5H13C13.663 15.5 14.2989 15.2366 14.7678 14.7678C15.2366 14.2989 15.5 13.663 15.5 13V9.66667C15.5 9.44565 15.4122 9.23369 15.2559 9.07741C15.0996 8.92113 14.8877 8.83333 14.6667 8.83333C14.4457 8.83333 14.2337 8.92113 14.0774 9.07741C13.9211 9.23369 13.8333 9.44565 13.8333 9.66667V13C13.8333 13.221 13.7455 13.433 13.5893 13.5893C13.433 13.7455 13.221 13.8333 13 13.8333H3C2.77899 13.8333 2.56702 13.7455 2.41074 13.5893C2.25446 13.433 2.16667 13.221 2.16667 13V3ZM10.5 0.5C10.279 0.5 10.067 0.587797 9.91074 0.744078C9.75446 0.900358 9.66667 1.11232 9.66667 1.33333C9.66667 1.55435 9.75446 1.76631 9.91074 1.92259C10.067 2.07887 10.279 2.16667 10.5 2.16667H12.655L7.41083 7.41083C7.25904 7.568 7.17504 7.7785 7.17694 7.997C7.17884 8.2155 7.26648 8.42451 7.42098 8.57901C7.57549 8.73352 7.7845 8.82116 8.003 8.82306C8.2215 8.82496 8.432 8.74096 8.58917 8.58917L13.8333 3.345V5.5C13.8333 5.72101 13.9211 5.93297 14.0774 6.08926C14.2337 6.24554 14.4457 6.33333 14.6667 6.33333C14.8877 6.33333 15.0996 6.24554 15.2559 6.08926C15.4122 5.93297 15.5 5.72101 15.5 5.5V1.33333C15.5 1.11232 15.4122 0.900358 15.2559 0.744078C15.0996 0.587797 14.8877 0.5 14.6667 0.5H10.5Z" />
               </svg>
            )}
            <span className={`text-white dark:text-dark-gray ${onCard ? 'md:dark:text-white' : ''} w-full text-center whitespace-nowrap px-2 py-1 xs:px-3`}>
               {title}
            </span>
         </div>
      </a>
   );
};
